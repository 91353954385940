<template>
  <div class="JourneysList">
    <div v-for="course in courses" :key="course.id" class="wrapper" @click="handleCourseToggleCollapse(course.id)">
      <div class="course">
        <div class="left-side">
          <h3>{{ $t(`dashboard.general.lifeskillLabels.${course.lifeskillNumber}`) }}</h3>
          <p>{{ course.stepGroupIds.length + ' ' + $t('journeys') }}</p>
        </div>
        <div class="right-side">
          <svgicon
            class="icon"
            :style="{ transform: course.id === expandedCourseId ? 'rotate(-180deg)' : 'rotate(0)' }"
            icon="chevron-down"
          />
        </div>
      </div>

      <TransitionExpandCollapse>
        <div v-if="course.id === expandedCourseId" class="course-journeys-list">
          <div
            v-for="journey in expandedJourneys"
            :key="journey.id"
            class="journey"
            :style="{
              border: journey.id === selectedJourneyId ? '1px solid #40ACE0' : '1px solid #363764',
            }"
            @click.stop="!doneJourneysIds.includes(journey.id) && setSelectedJourneyId(journey.id)"
          >
            <div class="right-side">
              <TeamChallengeJourneyIcon :label="journey.index + 1" class="journey-icon" />
              <p>{{ journeyName(journey) }}</p>
            </div>
            <div class="left-side">
              <img v-if="doneJourneysIds.includes(journey.id)" src="@/assets/images/green-checkmark-icn.png" />
              <p v-if="journey.id === selectedJourneyId">Selected</p>
            </div>
          </div>
        </div>
      </TransitionExpandCollapse>
    </div>
  </div>
</template>

<translations>
    journeys: Journeys
    journeys_no: Reiser
    journey: Journey
    journey_no: Reise
</translations>

<script>
import { mapGetters } from 'vuex';
import TransitionExpandCollapse from '@/components/TransitionExpandCollapse';
import TeamChallengeJourneyIcon from './TeamChallengeJourneyIcon';

export default {
  components: {
    TransitionExpandCollapse,
    TeamChallengeJourneyIcon,
  },

  props: {
    doneJourneysIds: {
      type: Array,
      required: false,
    },

    hideCompleted: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data() {
    return {
      expandedCourseId: null,
      selectedJourneyId: null,
    };
  },

  computed: {
    ...mapGetters({
      getJourneyById: 'moduleCourse/getJourneyById',
    }),

    courses() {
      return Object.values(this.$store.state.moduleCourse.courses).filter(
        course =>
          course.lifeskillNumber &&
          course.stepGroupIds.length &&
          course.stepGroupIds.some(stepGroupId =>
            this.hideCompleted ? !this.doneJourneysIds.includes(stepGroupId) : true
          )
      );
    },

    expandedJourneys() {
      return this.expandedCourseId
        ? this.courses
            .find(course => course.id === this.expandedCourseId)
            .stepGroupIds.map(id => this.getJourneyById(id))
            .filter(journey => (this.hideCompleted ? !this.doneJourneysIds.includes(journey.id) : true))
        : null;
    },
  },

  methods: {
    handleCourseToggleCollapse(id) {
      const shouldBeClosed = id === this.expandedCourseId;

      this.expandedCourseId = shouldBeClosed ? null : id;

      this.setSelectedJourneyId(null);
    },

    journeyName(journey) {
      return journey.name || this.$t('journey') + ' ' + (parseInt(journey.index) + 1);
    },

    setSelectedJourneyId(id) {
      this.selectedJourneyId = id;

      this.$emit('journeySelected', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.JourneysList {
  margin-right: 50px;
  @media (max-width: 1080px) {
    margin-right: 10px;
  }

  .wrapper {
    border-width: 0 0 1px 0;
    border-color: rgba(33, 41, 55, 0.5);
    border-style: solid;
    .course {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .left-side {
        h3 {
          font-weight: normal;
          font-size: 18px;
          line-height: 20px;
          color: #a6b1cb;
        }
        p {
          font-size: 13px;
          line-height: 20px;
          color: #a6b1cb;
          opacity: 0.4;
        }
      }
      .right-side {
        display: flex;
        align-items: center;
        padding: 25px;
        .icon {
          fill: #5983f7;
          width: 10px;
          height: 10px;
          transition: 0.5s;
        }
      }
    }
    .course-journeys-list {
      .journey {
        background-color: rgba(34, 33, 94, 0.3);
        border: 1px solid #363764;
        box-sizing: border-box;
        border-radius: 3.98527px;
        min-height: 100px;
        display: flex;
        margin-bottom: 15px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .right-side {
          display: flex;
          align-items: center;
          .journey-icon {
            min-width: 70px;
            min-height: 70px;
            max-width: 70px;
            max-height: 70px;
            margin-left: 10px;
            @media (max-width: 450px) {
              min-width: 60px;
              min-height: 60px;
              max-width: 60px;
              max-height: 60px;
            }
          }

          p {
            font-size: 12px;
            line-height: 32px;
            color: #e9e9e9;
            margin-left: 20px;
            margin-right: 20px;
          }
        }
        .left-side {
          img {
            margin: 0 15px;
          }
          p {
            margin-right: 30px;
            font-weight: 500;
            font-size: 12px;
            color: #40ace0;
            @media (max-width: 600px) {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
}
</style>
