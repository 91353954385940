<template>
  <div class="CreateTeamChallange">
    <div class="page-wrapper">
      <h3>{{ $t('title') }}</h3>
      <div class="wrapper">
        <div class="left-wrapper">
          <div class="top-part-wrapper">
            <nav>
              <a href="#" class="active">{{ $t('journeys_all') }}</a>
              <a href="#" class="recommended-journeys">{{ $t('journeys_recommended') }}</a>
            </nav>
            <div class="right-switch-wrapper">
              <p>{{ $t('hide_completed') }}</p>
              <div class="switch" @click="hideCompleted = !hideCompleted">
                <div
                  class="switch-button"
                  :class="{
                    'switch-right': !hideCompleted,
                    'switch-left': hideCompleted,
                  }"
                />
              </div>
            </div>
          </div>
          <JourneysList
            :done-journeys-ids="doneJourneysIds"
            :hide-completed="hideCompleted"
            @journeySelected="setJourneyId($event)"
          />
        </div>
        <div class="right-wrapper">
          <form action="#" @submit.prevent="handleSubmit">
            <h5>{{ $t('pick_date') }}</h5>
            <div class="date-inputs">
              <div class="input-wrapper">
                <fieldset>
                  <legend>{{ $t('from') }}</legend>
                  <input v-model="formData.start" type="date" />
                </fieldset>

                <error-output v-if="showErrors" :errors="startDateErrors" />
              </div>

              <div class="input-wrapper">
                <fieldset>
                  <legend>{{ $t('to') }}</legend>
                  <input v-model="formData.stop" type="date" />
                </fieldset>

                <error-output v-if="showErrors" :errors="endDateErrors" />
              </div>
            </div>
            <p v-if="formData.endDate" class="quiz-date-info">
              {{ $t('quiz_date_info') }} {{ formattedEndDate }}
            </p>
            <h5>{{ $t('shoutout') }}</h5>
            <textarea v-model="formData.shoutout" :placeholder="$t('shoutout_placeholder')" />
              <button>{{ $t('cta') }}</button>
            <error-output v-if="showErrors" :errors="selectedJourneyErrors" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<translations>
  title: Start a new team journey
  title_no: Start på en ny reise med teamet

  pick_date: PICK DATE
  pick_date_no: VELG DATO

  from: From
  from_no: Fra

  to: To
  to_no: Til

  shoutout: SHOUTOUT
  shoutout_no: MELDING

  quiz_date_info: Journey quiz will be available at
  quiz_date_info_no: Reise-quizzen blir tilgjengelig

  shoutout_placeholder: A personal message to your team mates
  shoutout_placeholder_no: En personlig melding til teamet ditt

  cta: Start now
  cta_no: Start nå

  journeys_all: ALL JOURNEYS
  journeys_all_no: ALLE REISER

  journeys_recommended: RECOMMENDED JOURNEYS
  journeys_recommended_no: ANBEFALTE REISER

  hide_completed: HIDE COMPLETED
  hide_completed_no: SKJUL FULLFØRTE

  select_error: Please select a journey
  select_error_no: Du må velge en reise

  start_required: Start of journey
  start_required_no: Starten for reisen

  stop_required: When to do the team quiz?
  stop_required_no: Dato for team quiz

  start_after_stop_error: Must be after start date
  start_after_stop_error_no: Må være etter start dato
</translations>

<script>
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import apolloClient from '@/apollo-client';
import getTeamsList from './graphql/GetTeamsList.gql';
import gql from 'graphql-tag';

import ErrorOutput from './ErrorOutput';
import JourneysList from './JourneysList';

const mustBeAfterStartDate = (value, formData) => {
  const startDate = moment(formData.start);
  const endDate = moment(formData.stop);

  return startDate.diff(endDate) < 0;
};

export default {
  components: {
    ErrorOutput,
    JourneysList,
  },

  mixins: [validationMixin],

  data() {
    return {
      hideCompleted: false,
      showErrors: false,
      teams: [],
      teamGoals: [],
      formData: {
        journeyId: null,
        teamId: null,
        createdBy: null,
        start: null,
        stop: null,
        shoutout: null,
      },
    };
  },

  validations: {
    formData: {
      journeyId: { required },
      start: { required },
      stop: { required, mustBeAfterStartDate },
    },
  },

  computed: {
    ...mapGetters({
      profile: 'moduleAuth/profile',
    }),

    formattedEndDate() {
      return moment(this.formData.end).format('LLL');
    },

    startDateErrors() {
      const errors = [];

      if (!this.$v.formData.start.required) {
        errors.push(this.$t('start_required'));
      }

      return errors;
    },

    endDateErrors() {
      const errors = [];

      if (!this.$v.formData.stop.required) {
        errors.push(this.$t('stop_required'));
      }

      if (!this.$v.formData.stop.mustBeAfterStartDate) {
        errors.push(this.$t('start_after_stop_error'));
      }

      return errors;
    },

    selectedJourneyErrors() {
      const errors = [];

      if (!this.$v.formData.journeyId.required) {
        errors.push(this.$t('select_error'));
      }

      return errors;
    },

    doneJourneysIds() {
      return this.teamGoals
        .filter(teamGoal => {
          const isTeamGoalCompleted =
            teamGoal.quizGameId &&
            this.$store.state.moduleMemoransier.games.find(game => game.id === teamGoal.quizGameId).leaderboardReveal;

          return isTeamGoalCompleted;
        })
        .map(teamGoal => teamGoal.journeyId);
    },
  },

  async mounted() {
    await this.$store.dispatch('moduleMemoransier/refresh');

    await this.setTeamsList();

    this.setAditionalFormData();

    const response = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          teamGoalsList {
            id
            shoutout
            start
            stop
            memoGameId
            quizGameId
            teamId
            journeyId
            createdBy
          }
        }
      `,
    });

    this.teamGoals = response.data.teamGoalsList;
  },

  methods: {
    async handleSubmit() {
      try {
        this.$v.$touch();

        if (this.$v.$invalid) {
          this.showErrors = true;

          return;
        }

        await apolloClient.mutate({
          fetchPolicy: 'no-cache',
          mutation: gql`
            mutation CreateTeamGoalMutation(
              $createdBy: String!
              $shoutout: String
              $start: Datetime
              $stop: Datetime
              $teamId: Int!
              $journeyId: String!
            ) {
              __typename
              createTeamGoal(
                input: {
                  teamGoal: {
                    createdBy: $createdBy
                    shoutout: $shoutout
                    start: $start
                    stop: $stop
                    teamId: $teamId
                    journeyId: $journeyId
                  }
                }
              ) {
                clientMutationId
                teamGoal {
                  shoutout
                  start
                  stop
                  teamId
                  journeyId
                  createdBy
                }
              }
            }
          `,
          variables: {
            ...this.formData,
          },
        });

        this.$router.push({
          name: 'DashboardHomeView',
        });
      } catch (error) {
        console.error(error);
      }
    },

    setJourneyId(id) {
      this.formData.journeyId = id;
    },

    setAditionalFormData() {
      this.formData.createdBy = this.profile.id;
      this.formData.teamId = parseInt(this.$route.params.teamId);
      this.setStartDateAsToday();
    },

    async setTeamsList() {
      try {
        const response = await apolloClient.query({
          query: getTeamsList,
        });

        this.teams = response.data.teamsList;
      } catch (error) {
        console.error(error);
      }
    },

    setStartDateAsToday() {
      const today = moment().format('YYYY-MM-DD');

      this.formData.start = today;
    },
  },
};
</script>

<style lang="scss" scoped>
.CreateTeamChallange {
  background: linear-gradient(180deg, #0a1335 0%, #1e1456 93.87%);
  padding: 80px 50px;
  overflow: scroll !important;
  @media (max-width: 600px) {
    padding: 50px 0 50px 20px;
  }
  .page-wrapper {
    max-width: 1440px;
    margin: 0 auto;
    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      color: #c9cfdc;
      margin-bottom: 35px;
    }
    .wrapper {
      display: flex;
      @media (max-width: 1080px) {
        flex-direction: column-reverse;
        align-items: center;
      }
      .left-wrapper {
        width: 60%;
        border-right: 1px solid rgba(33, 41, 55, 0.5);
        @media (max-width: 1080px) {
          width: 100%;
          margin-top: 50px;
        }
        @media (max-width: 1080px) {
          width: 100%;
          margin-top: 0;
        }
        .top-part-wrapper {
          display: flex;
          justify-content: space-between;
          border-width: 1px 0;
          border-color: rgba(33, 41, 55, 0.5);
          border-style: solid;
          padding-top: 40px;
          padding-bottom: 20px;
          nav {
            display: flex;
            align-items: center;
            a {
              font-weight: normal;
              font-size: 10px;
              line-height: 15px;
              letter-spacing: 0.1em;
              margin-right: 20px;
              color: #a6b1cb;
              text-decoration: none;
              &.active {
                color: #40ace0;
              }
            }

            .recommended-journeys {
              opacity: 30%;
            }
          }
          .right-switch-wrapper {
            display: flex;
            align-items: center;
            @media (max-width: 600px) {
              display: none;
            }
            p {
              font-weight: normal;
              font-size: 10px;
              line-height: 15px;
              text-align: right;
              letter-spacing: 0.1em;
              color: #a6b1cb;
              opacity: 0.5;
            }
            .switch {
              width: 25px;
              height: 10px;
              background: #404650;
              border-radius: 20.093px;
              display: flex;
              align-items: center;
              margin-left: 10px;
              cursor: pointer;
              margin-right: 70px;
              .switch-button {
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: #5983f7;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                transition: 0.5s;

                &.switch-right {
                  transform: translateX(0);
                }
                &.switch-left {
                  transform: translateX(100%);
                }
              }
            }
          }
        }
      }
      .right-wrapper {
        padding: 30px;
        padding-right: 0;
        border-top: 1px solid rgba(33, 41, 55, 0.5);
        width: 40%;
        @media (max-width: 1080px) {
          padding-left: 0;
          width: 100%;
        }
        @media (max-width: 600px) {
          padding-right: 20px;
        }
        form {
          @media (max-width: 1080px) {
            display: flex;
            flex-direction: column;
          }
          h5 {
            margin: 0;
            font-weight: normal;
            font-size: 10px;
            line-height: 11px;
            letter-spacing: 0.1em;
            color: #a6b1cb;
            margin-bottom: 20px;
            &:nth-of-type(2) {
              margin-top: 30px;
            }
          }

          .date-inputs {
            display: flex;
            @media (max-width: 600px) {
              flex-direction: column;
            }
            fieldset {
              background: #18194d;
              flex: 1;
              border: 1px solid #a6b1cb;
              margin-left: 0;
              &:first-of-type {
                margin-right: 10px;
              }
              legend {
                color: #a6b1cb;
                font-size: 13px;
              }
              input {
                background: #18194d;
                outline: none;
                border: none;
                color: #a6b1cb;
                width: 100%;
                &::-webkit-calendar-picker-indicator {
                  filter: invert(80%);
                  cursor: pointer;
                }
              }
            }
          }
          p {
            color: #a4b1cd;
            opacity: 0.5;
            font-size: 10px;
            line-height: 15px;
          }
          textarea {
            width: 100%;
            resize: none;
            height: 150px;
            background: #18194d;
            border: none;
            border-radius: 2px;
            padding: 15px;
            color: #a6b1cb;
            font-size: 13px;
            &::placeholder {
              color: #a6b1cb;
              font-size: 13px;
            }
          }
          button {
            background: #40ace0;
            border-radius: 4.76651px;
            width: 100%;
            height: 60px;
            border: none;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: rgba(255, 255, 255, 0.9);
            margin-top: 20px;
            cursor: pointer;
            @media (max-width: 1080px) {
              width: 40%;
              align-self: center;
            }
            @media (max-width: 600px) {
              width: 60%;
            }
          }
        }
      }
    }
  }
}
</style>
