<template>
    <div class="ErrorOutput"> {{ errors[0] }} </div>
</template>

<script>
export default {
    props: {
        errors: {
            type: Array,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.ErrorOutput {
    color: red;
    font-size: 12px;
    padding: 10px 0;
}
</style>
